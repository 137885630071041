import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getTrustedRelation, getTrustedRelations, saveTrustedRelation } from './TrustedRelationApi';
import { TrustedRelation } from '../model/TrustedRelation';


export interface TrustedRelationState extends GenericPageableState<TrustedRelation> {
}

export const trustedRelationSlice = createSlice({
    name: 'trustedRelation',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getTrustedRelations);
        addGenericStateSingleBuilders(builder, getTrustedRelation);
        addGenericStateSingleBuilders(builder, saveTrustedRelation);
    }
});
