import React, { useMemo } from 'react';
// import * as DOMPurify from 'dompurify';
import './TextToHtml.css'
import DOMPurify from 'dompurify';

export interface TextToHtmlProps {
    value?: string | undefined;
}

export const TextToHtml: React.FC<TextToHtmlProps> = (props) => {

        const htmlSanitized = useMemo(() => {
            if (props.value === undefined) {
                return '';
            }

            return DOMPurify.sanitize(props.value!);
        }, [props.value]);

        if (props.value === undefined) {
            return null;
        }

        function isUrl(value: string) {
            if (typeof value !== 'string') {
                return false;
            }
            return (value.startsWith('http://') || value.startsWith('https://'));
        }

        if (isUrl(props.value)) {
            return (
                <a href={DOMPurify.sanitize(props.value!)} target="_blank" rel="noreferrer"><span className="text-to-html"
                                                                                                  dangerouslySetInnerHTML={{
                                                                                                      __html: htmlSanitized,
                                                                                                  }}
                ></span><li className="ml-1 text-xs pi pi-external-link"></li></a>
            );
        } else {
            return (
                <span className="text-to-html"
                      dangerouslySetInnerHTML={{
                          __html: htmlSanitized,
                      }}
                ></span>
            )
        }
    }
;

