import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { addQueryParam } from '../slice';
import { TrustedRelation } from '../model/TrustedRelation';


export const getTrustedRelations = createAsyncThunk(
    'trustedRelation/getTrustedRelations', ({jwtToken, page, pageSize, searchText}: { jwtToken: string | undefined, page: number, pageSize: number, searchText?: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedrelation';
        url = addQueryParam(url, 'page', page);
        url = addQueryParam(url, 'size', pageSize);
        url = addQueryParam(url, 'q', searchText);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getTrustedRelation = createAsyncThunk(
    'trustedRelation/getTrustedRelation', ({jwtToken, trustedRelationId}: { jwtToken: string | undefined, trustedRelationId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = '/trustedrelation/' + trustedRelationId;
        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveTrustedRelation = createAsyncThunk(
    'trustedRelation/saveTrustedRelation', ({jwtToken, trustedRelation}: { jwtToken: string | undefined, trustedRelation: TrustedRelation }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/trustedrelation';
        if (trustedRelation.id !== undefined) {
            url = url + '/' + trustedRelation.id;
        }
        return axios.post(url, trustedRelation, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
