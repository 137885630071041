import React, { useRef } from 'react';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { Logo } from '../atoms';
import { Image } from 'primereact/image';

interface ImageUploadProps {
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    title?: string | undefined;
    value: string;
    onUpload: (base64EncodedImage: string) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
    const fileUploadRef = useRef<FileUpload>(null);

    const uploadImage = async (event: FileUploadHandlerEvent) => {
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

        reader.readAsDataURL(blob);

        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data !== undefined) {
                props.onUpload(base64data!.toString());
            }
        };
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };


    return (
        <div style={{display: 'grid', gridTemplate: '1fr / 1fr', placeItems: 'center'}}
             onClick={event => {
                 fileUploadRef.current?.getInput().click();
             }}>
            <div className="flex align-items-center justify-content-center mr-2 p-8"
                 style={{borderColor: "#CBD5E0", maxHeight: "161px", maxWidth: "193px", backgroundColor: "#FFFFFF", borderWidth: 1, borderStyle: "solid", gridColumn: '1 / 1', gridRow: '1 / 1', zIndex: 1}}
            >
                {(props.value === undefined) && (
                    <Logo className="flex align-self-start"/>
                )}
                {(props.value !== undefined) && (
                    <Image src={props.value} alt="Logo" width="120"/>
                )}
            </div>
            <span className="pi pi-pen-to-square text-white text-3xl" style={{gridColumn: '1 / 1', gridRow: '1 / 1', zIndex: 1, backgroundColor: 'rgba(99,99,99,0.51)', borderRadius: '4px', padding: 10}}
            />
            <FileUpload ref={fileUploadRef} mode="basic" className="hidden" name="demo[]" accept="image/jpeg,image/png" maxFileSize={1000000} customUpload uploadHandler={uploadImage} auto/>
        </div>

    );
};


