import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState, WalletProfile } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { addCredentialToPublicProfile, deleteServiceFromWalletProfile, getWalletProfile, saveWalletProfile } from './WalletProfileApi';


export interface WalletProfileState extends GenericState<WalletProfile> {
}

export const walletProfileSlice = createSlice({
    name: 'walletProfile',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getWalletProfile);
        addGenericStateSingleBuilders(builder, saveWalletProfile);
        addGenericStateSingleBuilders(builder, addCredentialToPublicProfile);
        addGenericStateSingleBuilders(builder, deleteServiceFromWalletProfile);
    },
});

