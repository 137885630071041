import React from 'react';
import { Credential } from '../../state';
import { OCard } from './OCard';
import { CredentialStatusBadge } from './CredentialStatusBadge';
import { CredentialCard } from './CredentialCard';
import { OFieldLabel, OFieldValue, OH3 } from '../atoms';
import { useTranslation } from 'react-i18next';
import { TextToHtml } from './TextToHtml';

interface DidCredentialViewProps {
    className?: string | undefined;
    credential: Credential;
    onEditClicked?: (credential: Credential) => void;
    onDeletedClicked?: (credential: Credential) => void;

}

export const DidCredentialView: React.FC<DidCredentialViewProps> = (props) => {
    const {t} = useTranslation();
    const getAttributes = (attributeName: any, valueObj: any): { attributeName: string, value: any }[] => {

        if (typeof valueObj === 'object') {
            const x = Object.getOwnPropertyNames(valueObj)
                .flatMap(subValue => {
                    return getAttributes(subValue, valueObj[subValue]);
                    // @ts-ignore
                }).flat();
            return x;
        } else {
            return [{attributeName: attributeName, value: valueObj}]
        }
    };

    function getDisplayLabel(attr: string) {
        if ((props.credential?.displayProperties?.credentialSubjectDisplay === undefined) || (props.credential?.displayProperties?.credentialSubjectDisplay[attr] === undefined)) {
            return attr.charAt(0).toUpperCase() + attr.slice(1).replace('-', ' ');
        }
        // @ts-ignore
        return props.credential?.displayProperties?.credentialSubjectDisplay[attr]?.name ?? attr.charAt(0).toUpperCase() + attr.slice(1).replace('-', ' ');
    }

    function getActions() {
        return (<div className="text-gray-400 cursor-pointer">
            {(props.onEditClicked !== undefined) && (<span className="white-space-nowrap" onClick={() => props.onEditClicked && props.onEditClicked(props.credential)}><i className="pi pi-pencil ml-2 mr-2"/>Edit</span>)}
            {(props.onDeletedClicked !== undefined) && (<span className="white-space-nowrap" onClick={() => props.onDeletedClicked && props.onDeletedClicked(props.credential)}><i className="pi pi-trash mr-2"/>Delete</span>)}
        </div>)
    }

    return (
        <OCard className={"grid ml-0 mr-0 " + props.className}>
            <div className="col-12 lg:col-3 border-right-0 lg:border-right-1 border-black-alpha-10 pl-0 pr-0">
                <div className="flex justify-content-between">
                    <div className="font-semibold mb-2">{props.credential.displayProperties?.issuerDisplay?.displayName}</div>
                    <div className="inline-block lg:hidden">{getActions()}</div>
                </div>
                <CredentialStatusBadge className="mb-2" status={props.credential.status?.credentialStatus} validationPolicyResult={props.credential.status?.policyResults}/>
                <CredentialCard credential={props.credential} scaleFactor={0.5}/>
            </div>
            <div className="col-12 lg:col-9 ml-0 lg:pl-4">
                <div className="formgrid">
                    <div className="flex justify-content-between">
                        <OH3 className="mb-4">{t('screens.credentialDetail.title')}</OH3>
                        <div className="hidden lg:inline-block">{getActions()}</div>
                    </div>
                    {props.credential && getAttributes('', props.credential?.credentialSubject)
                        .filter((attr) => attr.attributeName !== 'id')
                        .map((attr, index) => (
                            <div className="field grid" key={index}>
                                <OFieldLabel className="col-4">{getDisplayLabel(attr.attributeName)}</OFieldLabel>
                                <OFieldValue className="col-8"><TextToHtml value={attr.value}/></OFieldValue>
                            </div>
                        ))}
                    {(props.credential?.issuanceDate) && (
                        <div className="field grid">
                            <OFieldLabel className="col-4">{t('screens.credentialDetail.issuanceDate')}</OFieldLabel>
                            <OFieldValue className="col-8">{new String(props.credential?.issuanceDate)}</OFieldValue>
                        </div>
                    )}
                </div>
                <div>
                    <OH3 className="mt-6 mb-4">{t('screens.credentialDetail.issuer')}</OH3>
                    <div className="field grid">
                        <OFieldLabel className="col-4">{t('screens.credentialDetail.issuer')}</OFieldLabel>
                        <OFieldValue className="col-8"><TextToHtml value={props.credential?.displayProperties?.issuerDisplay?.displayName}/></OFieldValue>
                    </div>
                    <div className="field grid">
                        <OFieldLabel className="col-4">{t('screens.credentialDetail.credentialType')}</OFieldLabel>
                        <OFieldValue className="col-8">{props.credential?.displayProperties?.credentialTypeDisplay?.displayName}</OFieldValue>
                    </div>
                    {(props.credential?.displayProperties?.credentialTypeDisplay?.description) && (
                        <div className="field grid">
                            <OFieldLabel className="col-4">{t('screens.credentialDetail.credentialTypeDescription')}</OFieldLabel>
                            <OFieldValue className="col-8">{props.credential?.displayProperties?.credentialTypeDisplay?.description}</OFieldValue>
                        </div>
                    )}
                </div>
                {props.credential?.termsOfUse && props.credential?.termsOfUse.length > 0 ?
                    <div>
                        <OH3 className="mt-6 mb-4">{t('screens.credentialDetail.termsOfUse.title')}</OH3>
                        {props.credential?.termsOfUse?.map((item) => <div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.credentialDetail.termsOfUse.id')}</OFieldLabel>
                                <OFieldValue className="col-8">{item.id}</OFieldValue>
                            </div>
                            <div className="field grid">
                                <OFieldLabel className="col-4">{t('screens.credentialDetail.termsOfUse.type')}</OFieldLabel>
                                <OFieldValue className="col-8">{item.type}</OFieldValue>
                            </div>
                        </div>)}
                    </div> : <></>}
            </div>
        </OCard>
    );
};

