import React, { CSSProperties, HTMLInputTypeAttribute, useEffect, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { Controller, FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { FieldPath } from 'react-hook-form/dist/types';
import { v4 as uuidv4 } from 'uuid';
import { InputErrorLabel } from '../atoms';


export interface FormTextInputProps {
    value?: string | undefined;
    onChangeValue?: (value: string | undefined) => void;
    inputType?: HTMLInputTypeAttribute | undefined;
    placeHolder?: string | undefined;
    className?: string | undefined;
    style?: CSSProperties | undefined;
    formHook: UseFormReturn<any, any, FieldValues | undefined>;
    controlName?: string | undefined;
    rules?: Omit<RegisterOptions<FieldValues, FieldPath<FieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

export const FormTextInput: React.FC<FormTextInputProps> = (props) => {

    const controlName = useMemo(() => (props.controlName === undefined ? uuidv4(): props.controlName), []);
    const currentValue: string = useWatch({
        control: props.formHook.control,
        name: controlName,
        defaultValue: props.value ?? '',
    });

    const {inputType = 'text'} = props;

    useEffect(() => {
        props.formHook?.setValue(controlName, props.value ?? '');
    }, [props.value]);

    useEffect(() => {
        if (props.onChangeValue !== undefined) {
            props.onChangeValue(currentValue);
        }
    }, [currentValue]);

    function hasErrors(): boolean {
        return Object.keys(props.formHook?.formState.errors || {}).includes(controlName);
    }

    function getErrors(): string {
        return props.formHook?.formState.errors[controlName]?.message + '';
    }

    function getBorderColor(hasErrors: boolean): CSSProperties {
        if (hasErrors) {
            return {border: 'var(--red-500) solid 1px'};
        } else {
            return {};
        }

    }

    return (
        <Controller name={controlName} control={props.formHook?.control}
                    rules={props.rules}
                    render={({field}) => {
                        let value = (field.value === undefined) ? '' : field.value;
                        return (
                            <div className={props.className}>
                                <InputText id={field.name} {...field} className={props.className} value={value} style={Object.assign({}, props.style, getBorderColor(hasErrors()))}
                                           onChange={(event) => field.onChange(event.target.value)}
                                           type={inputType}
                                           placeholder={props.placeHolder}/>
                                <div>{hasErrors() && (
                                    <InputErrorLabel className="ml-1">{getErrors()}</InputErrorLabel>
                                )}
                                </div>

                            </div>
                        );
                    }}/>
    );
};

