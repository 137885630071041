import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, DidService, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { addKey, addService, createDid, deleteService, getDid, getDids, registerTrustedIssuer, revokeKey, setDefaultDid, updateDid } from './DidApi';

export interface Did {
    id: number;
    did: string;
    type: string;
    displayName?: string;
    createdAt?: Date;
    createdBy?: string;
    lastModifiedAt?: Date;
    lastModifiedBy?: string;
    document?: any;
    keys: Key[];
    services: DidService[];
    environment?: string;
    defaultDid: boolean
}

export interface Key {
    keyId: string;
    alias: string;
    purposes: string[];
}

export interface DidForm {
    id?: number;
    type: String;
    displayName?: string;
    options?: any;
}

export interface TrustedIssuerForm {
    didId?: number;
    schemaId?: string;
    taoWalletAddress?: string;
}

export interface DidState extends GenericState<Did> {
}

export const didSlice = createSlice({
    name: 'did',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateListBuilders(builder, getDids);
        addGenericStateSingleBuilders(builder, getDid);
        addGenericStateListBuilders(builder, createDid);
        addGenericStateListBuilders(builder, updateDid);
        addGenericStateListBuilders(builder, setDefaultDid);
        addGenericStateListBuilders(builder, addKey);
        addGenericStateListBuilders(builder, revokeKey);
        addGenericStateListBuilders(builder, addService);
        addGenericStateListBuilders(builder, deleteService);
        addGenericStateSingleBuilders(builder, registerTrustedIssuer);
    },
});
