import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { FormTextInputWithLabel, ImageUpload, OCard } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { getUser, getWalletProfile, saveWalletProfile, useAppDispatch, walletProfileSelector } from '../../../state';
import { useForm } from 'react-hook-form';

export const PublicProfileDetailsForm: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    let navigate = useNavigate();

    let walletProfile = useSelector(walletProfileSelector);
    const formHook = useForm({
        defaultValues: walletProfile.singleItem
    });
    const {watch, handleSubmit, reset, setValue, formState: {errors}} = formHook;

    useEffect(() => {
        dispatch(getWalletProfile({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    useEffect(() => {
        reset(walletProfile.singleItem);
    }, [walletProfile.singleItem]);

    const logo = watch("base64EncodedLogo")

    return (
        <>
            <OCard className="">
                <form
                    onSubmit={handleSubmit((data) => {
                        dispatch(saveWalletProfile({jwtToken: keycloak.token!, walletProfile: data})).then((response) => {
                            if (response.type.includes('fulfilled')) {
                                dispatch(getUser({jwtToken: keycloak.token!}));
                                setTimeout(() => { // Use timeout the give time to update the redux store.
                                    navigate('/profile');
                                }, 250);
                            }
                        });
                    })}
                >
                    <div className="grid">
                        <div className="col-12 md:col-3">
                            <ImageUpload value={logo} onUpload={base64EncodedImage => setValue('base64EncodedLogo', base64EncodedImage)}/>
                        </div>
                        <div className="col-12 md:col-9">
                            <FormTextInputWithLabel className="w-full mb-2"
                                                    label={t('screens.walletProfile.organizationName.label')}
                                                    placeHolder={t('screens.walletProfile.organizationName.placeHolder')}
                                                    formHook={formHook}
                                                    controlName="organizationName"
                                                    rules={{
                                                        required: {value: true, message: t('error.inputErrors.requiredField')}
                                                    }}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.website.label')}
                                           placeHolder={t('screens.walletProfile.website.placeHolder')}
                                           formHook={formHook}
                                           controlName="website"
                                           rules={{
                                               pattern: {value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i, message: t('error.inputErrors.invalidUrl')}
                                           }}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.phoneNumber.label')}
                                           placeHolder={t('screens.walletProfile.phoneNumber.placeHolder')}
                                           formHook={formHook}
                                           controlName="phoneNumber"
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.email.label')}
                                           placeHolder={t('screens.walletProfile.email.placeHolder')}
                                           formHook={formHook}
                                           controlName="email"
                                           rules={{
                                               pattern: {value: /^\S+@\S+$/i, message: t('error.inputErrors.invalidEmail')}
                                           }}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.addressStreet.label')}
                                           placeHolder={t('screens.walletProfile.addressStreet.placeHolder')}
                                           formHook={formHook}
                                           controlName="addressStreet"
                                           rules={{}}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.addressPostalCode.label')}
                                           placeHolder={t('screens.walletProfile.addressPostalCode.placeHolder')}
                                           formHook={formHook}
                                           controlName="addressPostalCode"
                                           rules={{}}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.addressCity.label')}
                                           placeHolder={t('screens.walletProfile.addressCity.placeHolder')}
                                           formHook={formHook}
                                           controlName="addressCity"
                                           rules={{}}
                            />
                            <FormTextInputWithLabel className="w-full mb-2"
                                           label={t('screens.walletProfile.addressCountry.label')}
                                           placeHolder={t('screens.walletProfile.addressCountry.placeHolder')}
                                           formHook={formHook}
                                           controlName="addressCountry"
                                           rules={{}}
                            />
                            <Button className="mt-4" type="submit">{t('generic.save')}</Button>
                        </div>
                    </div>
                </form>
            </OCard>
        </>
    );
};
