import { createSlice } from '@reduxjs/toolkit';
import { Credential, defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { deleteCredential, getCredential, getCredentials } from './CredentialApi';


export interface CredentialState extends GenericPageableState<Credential> {
}

export const credentialSlice = createSlice({
    name: 'credential',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getCredentials);
        addGenericStateSingleBuilders(builder, getCredential);
        addGenericStateSingleBuilders(builder, deleteCredential);
    },
});

export interface CredentialAttribute {
    label: string;
    value: any;
    Show: boolean
}

export const getCredentialAttributes = (credential: Credential) => {

}
