import React from 'react';
import { IconProps } from './IconProps';


export const PublicProfile: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M17.5 2.5H2.50004C1.58337 2.5 0.833374 3.25 0.833374 4.16667V15.8333C0.833374 16.75 1.58337 17.5 2.50004 17.5H17.5C18.4167 17.5 19.1667 16.75 19.1667 15.8333V4.16667C19.1667 3.25 18.4167 2.5 17.5 2.5ZM17.5 15.8333H2.50004V4.16667H17.5V15.8333ZM4.16671 8.33333H11.6667V10H4.16671V8.33333ZM4.16671 5.83333H11.6667V7.5H4.16671V5.83333Z"
                fill="#343330"/>
        </svg>
    )
};
