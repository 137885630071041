import * as React from 'react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { DataView, DataViewPageEvent } from 'primereact/dataview';
import { Credential, credentialSelector, getCredentials, getUserPreferenceWithDefault, getWalletProfile, useAppDispatch, userSelector, walletProfileSelector } from '../../../state';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { EmptyWallet } from '../../atoms';
import { CredentialCard, CredentialStatusBadge, OCard, SearchEntry } from '../../molecules';
import { useTranslation } from 'react-i18next';


export const SelectCredentialToAdd: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    let navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>();
    const [layout, setLayout] = useState<'list' | 'grid'>('grid');

    let credentials = useSelector(credentialSelector);
    let user = useSelector(userSelector).singleItem;
    const [tableParams, setTableParams] = useState<DataViewPageEvent>({
        first: 0,
        rows: Number(getUserPreferenceWithDefault(user, 'credentialList.pageSize', "10")),
        page: 0,
        pageCount: 1
    });

    let walletProfile = useSelector(walletProfileSelector);

    useEffect(() => {
        if (walletProfile.singleItem === undefined) {
            dispatch(getWalletProfile({jwtToken: keycloak.token!}));
        }
    }, [keycloak.token, walletProfile.singleItem]);

    useEffect(() => {
        dispatch(getCredentials({jwtToken: keycloak.token!, locale: user?.locale, page: tableParams.page, pageSize: tableParams.rows, searchText: searchText, subjectDidId: walletProfile.singleItem?.defaultDidId}));
    }, [keycloak.token, user?.locale, tableParams.page, tableParams.rows, searchText, layout, walletProfile.singleItem?.defaultDidId]);

    useEffect(() => {
        setTableParams({...tableParams, pageCount: credentials.totalPages});
    }, [credentials.totalPages]);

    function handleRequest() {
        navigate(`/credential/new`)
    }

    function handleSelectCredential(credential: Credential) {
        navigate(`/credential/details/${credential.id}`)
    }

    const gridItem = (credential: Credential): ReactNode => {
        return (
            <OCard className="w-full flex flex-row p-2 m-2 align-items-center justify-content-between" onClick={() => handleSelectCredential(credential)}>
                <div className="flex flex-row col-10 p-2 m-2 align-items-center justify-content-start">
                    <div className="pr-3">
                        {(Object.getOwnPropertyNames(credential).length > 0) && (
                            <CredentialCard credential={credential} key='{index}' scaleFactor={0.6}/>
                        )}
                    </div>
                    <div className="">{credential.displayProperties?.credentialTypeDisplay?.displayName}</div>
                </div>
                <div className="col-2 flex flex-row align-items-end justify-content-end flex-grow-2 pr-4">
                    <CredentialStatusBadge status={credential?.status?.credentialStatus} validationPolicyResult={credential?.status?.policyResults}/>
                </div>
            </OCard>
        );
    }

    function onSearch(searchText: string | undefined) {
        setTableParams({...tableParams, page: 0});
        setSearchText(searchText);
    }

    const showEmptyPage = useMemo(() => {
        if (credentials?.list?.length > 0) {
            return false;
        } else {
            return (searchText === undefined) || (searchText!.length === 0)
        }
        ;
    }, [credentials?.list, searchText]);

    const credentialsToShow = useMemo(() => {
        return [...credentials.list]
    }, [credentials.list])

    return (
        <div className="mb-8">
            {(!showEmptyPage) && (
                <div>
                    <SearchEntry title={t('screens.credentialList.searchTitle')} onSearch={onSearch} className="mb-4"/>
                    <div className="m-4 text-left" style={{color: "#757575"}}>
                        {t('screens.selectCredentialToAdd.description1')}<br/>
                        {t('screens.selectCredentialToAdd.description2')}
                    </div>
                    <DataView
                        className="mt-3"
                        value={credentialsToShow}
                        itemTemplate={gridItem}
                        emptyMessage={t('screens.credentialList.searchNoCredentialsFound')}
                        rows={credentials.list.length}
                        totalRecords={credentials.totalElements}/>
                </div>
            )
            }
            {
                (showEmptyPage) && (
                    <div className="flex align-items-center  flex-wrap flex-column md:mt-8 pt-8">
                        <EmptyWallet color="#cae6fc"/>
                        <div className="m-4 text-center max-w-25rem">{t('screens.credentialList.emptyListMessage')}</div>
                        <Button className="mt-4 mb-8" onClick={handleRequest}>{t('screens.credentialList.requestNewCredential')}</Button>
                    </div>
                )}
        </div>
    );
}
