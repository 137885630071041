import React, { useMemo } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { PolicyResult } from '../../state';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';


export interface CredentialStatusBadgeProps {
    status?: string;
    validationPolicyResult?: PolicyResult[];
    className?: string | undefined;
}

export const CredentialStatusBadge: React.FC<CredentialStatusBadgeProps> = (props) => {
    const {t} = useTranslation();

    function getColor() {
        switch (props.status) {
            case 'UNCHECKED':
                return '#a8a8a8';
            case 'VALID':
                return '#38A169';
            case 'INVALID':
                return '#E63946';
            case 'EXPIRED':
                return '#E63946';

        }
    }

    function getIcon(isValid: boolean) {
        if (isValid) {
            return <i className="pi pi-check-circle text-green-500 mr-2"/>
        } else {
            return <i className="pi pi-times-circle text-red-500 mr-2"/>
        }
    }

    const controlName = useMemo(() => (uuidv4()), []);
    return (
        <>
            <Tooltip target={".custom-tooltip-btn" + controlName}>
                <div className="p-1 md:p-4">
                    <div className="pt-2 pb-2 font-bold">Validation checks</div>
                    {props.validationPolicyResult?.map((policy, index) => (
                        <div key={index} className="pt-2 pb-2">
                            <div className="font-bold">{getIcon(policy.success)}{policy.name}</div>
                            <div className="text-sm">{policy.description}</div>
                        </div>
                    ))}
                </div>
            </Tooltip>
            <div className={"inline-block p-2 pl-3 pr-3 text-xs text-white text-center custom-tooltip-btn" + controlName + " " + props.className} data-pr-position="left" style={{borderRadius: '16px', backgroundColor: getColor()}}>{t('fields.credentialStatus.' + props.status)}</div>
        </>
    );
};

