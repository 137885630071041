import React, { PropsWithChildren } from 'react';
import { Card } from 'primereact/card';
import { Did } from '../../state/slices/did';


export interface DidCardProps {
    did: Did;
    className?: string | undefined;
    onClick?: (did: Did) => void;
}

export const DidCard: React.FC<DidCardProps & PropsWithChildren> = (props) => {
    return (
        <Card className={props.className} title={
            <div className="flex justify-content-between">
                <div>{props.did.type.toUpperCase()}</div>
                {props.did.defaultDid && <i className="pi pi-star"/>}
            </div>
        } onClick={event => props.onClick!(props.did)}>
            <div>
                <p className="m-0">{props.did.displayName}</p>
            </div>
        </Card>
    );
};

