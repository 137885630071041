import React from 'react';
import { DidService } from '../../state';
import { OCard } from './OCard';
import { OFieldLabel, OFieldValue } from '../atoms';
import { useTranslation } from 'react-i18next';
import { TextWithExternalLink } from './TextWithExternalLink';

interface DidServiceViewProps {
    className?: string | undefined;
    didService: DidService;

}

export const DidServiceView: React.FC<DidServiceViewProps> = (props) => {
    const {t} = useTranslation();
    return (
        <OCard className={" " + props.className}>
                <div className="field grid">
                    <OFieldLabel className="col-12 lg:col-3">{t('fields.didService.single.serviceId')}</OFieldLabel>
                    <OFieldValue className="col-12 lg:col-9 pl-0 lg:pl-4">{props.didService?.serviceId}</OFieldValue>
                </div>
                <div className="field grid">
                    <OFieldLabel className="col-12 lg:col-3">{t('fields.didService.single.serviceType')}</OFieldLabel>
                    <OFieldValue className="col-12 lg:col-9 pl-0 lg:pl-4">{props.didService?.serviceType}</OFieldValue>
                </div>
                <div className="field grid mb-0">
                    <OFieldLabel className="col-12 lg:col-3">{t('fields.didService.single.serviceEndpoint')}</OFieldLabel>
                    <OFieldValue className="col-12 lg:col-9 pl-0 lg:pl-4"><TextWithExternalLink link={props.didService?.serviceEndpoint}/></OFieldValue>
                </div>
        </OCard>
    );
};

