import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { WalletProfile } from '../model';


export const getWalletProfile = createAsyncThunk(
    'walletProfile/getWalletProfile', ({jwtToken}: { jwtToken: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/wallet/profile', config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveWalletProfile = createAsyncThunk(
    'walletProfile/saveWalletProfile', ({jwtToken, walletProfile}: { jwtToken: string | undefined, walletProfile: WalletProfile }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.post('/wallet/profile', walletProfile, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const addCredentialToPublicProfile = createAsyncThunk(
    'walletProfile/addCredentialToProfile', ({jwtToken, credentialId}: { jwtToken: string | undefined, credentialId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.post(`wallet/profile/credential/${credentialId}`, null, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);


export const deleteServiceFromWalletProfile = createAsyncThunk(
    'walletProfile/deleteCredential', ({jwtToken, credentialId}: { jwtToken: string | undefined, credentialId: string | number}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        return axios.delete('/wallet/profile/service/' + credentialId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

